@import "./colors.scss";
 
.navbar-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.navbar-div-white {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white !important;
  color: $primary;
}
.img-logo {
  width: 183px;
  height: 46px;
  margin: 24px 35px;
  cursor: pointer;
}
.img-incliva {
  width: 420px;
  height: 35px;
  margin: 24px 35px;
  cursor: pointer;
}
 
.user-info-div {
  display: flex;
  margin-right: 50px;
  align-items: center;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}
.btn-edit-profile {
  font-family: "Lato";
  color: $grey-mid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
 
  &-pressed {
    color: $accent;
  }
&:hover {
    color: $accent;
  }
&:active {
    color: $accent;
  }
&:disabled {
    background: $grey-bg;
  }
}